@media (--breakpoint-medium) {
	.largeTab {
		width: 7rem;
		max-width: 7rem;
	}
}

/* This represents and "extra large" breakpoint */
@media (width >= 72em) {
	.largeTab {
		width: 10rem;
		max-width: 10rem;
	}
}
