.inputGroup {
	display: flex;
	flex-wrap: nowrap;

	& input {
		flex: 1;
	}

	& button {
		margin-left: -1px;
		width: unset;
	}

	& :not(:last-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	& :not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.focusWithin:focus-within {
	outline: auto;
	outline-color: -webkit-focus-ring-color;
}
