.backgroundImageGradientFull {
	&::after {
		content: '';
		position: absolute;
		inset: 0;
		margin: auto;
		background-image: linear-gradient(to right, #000000d9 0%, #0009 25%, #0000 100%);
	}
}

@media (--breakpoint-not-small) {
	.backgroundImageGradientFull {
		min-height: 444px;
	}
}
