.jumpTabSlideInAnimation {
	transition: 0.3s ease-in-out;
	top: 0;
	left: 0;
	position: fixed;
	box-shadow: 0 0 8px 2px rgb(0 0 0 / 20%);
}

.jumpTabButton:hover {
	color: var(--theme-primary);
}

.dropDownShadow {
	box-shadow: 0 8px 24px 0 rgb(0 0 0 / 20%);
}
