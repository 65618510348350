.banner {
	padding-bottom: 38%;
}

@media (--breakpoint-not-small) {
	.banner {
		padding-bottom: 16%;
	}
}

.backgroundImageGradientSavingsCenter {
	&::after {
		content: '';
		position: absolute;
		inset: 0;
		margin: auto;
		background-image: linear-gradient(to top, rgb(0 0 0 / 85%) 0%, rgb(23 23 23 / 50%) 45%, rgb(0 0 0 / 0%) 100%);
		z-index: 1;
	}
}
