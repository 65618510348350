.contentImageHeight {
	height: 13.875rem;
}

@media (--breakpoint-not-small) {
	.evenNumberLayout {
		display: grid;
		grid-template: auto 1fr / 1fr 1fr;
		grid-template-areas:
			'richText image'
			'richText image';
	}

	.oddNumberLayout {
		display: grid;
		grid-template: auto 1fr / 1fr 1fr;
		grid-template-areas:
			'image richText'
			'image richText';
	}

	.contentImage {
		grid-area: image;
	}

	.contentRichText {
		grid-area: richText;
		align-self: center;
	}

	.contentSectionHeroImage {
		&::after {
			content: '';
			position: absolute;
			inset: 0;
			margin: auto;
			background-image: linear-gradient(to right, #000000d9 0%, #0009 25%, #0000 100%);
		}
	}

	.contentImageHeight {
		height: 27.75rem;
	}
}
