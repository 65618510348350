.relatedArticleBgImg {
	background-position: 50%;
	padding-bottom: 60%;
}

.relatedArticleOverlay {
	max-height: 5rem;
	right: 0;
}

@media (--breakpoint-not-small) {
	.relatedArticleOverlay {
		border: none;
		max-height: none;
	}
}
