.infoWrapper {
	min-height: auto;
}

@media (--breakpoint-not-small) {
	.flexBasisCard {
		flex-basis: 25%;
	}

	.infoWrapper {
		min-height: 4rem;
	}
}

.cardMinWidth {
	min-width: 11rem;
}

@media (--breakpoint-large) {
	.flexBasisCard {
		flex-basis: 20%;
	}
}
