.modalMaxWidthSmall {
	max-width: 450px;
}

.modalMaxWidthNormal {
	max-width: 600px;
}

.modalMaxWidthLarge {
	max-width: 900px;
}

.modalHeightStretch {
	height: 90%;
}

.closeButtonStyle {
	top: 0.2rem;
	right: 0.2rem;
}

.modalMaxWidth {
	max-width: 100vw;
}

:global([class^='lock-body']) {
	overflow: hidden;
}
