.banner {
	padding-bottom: 38%;
}

.overlayIcon {
	right: 30%;
}

@media (--breakpoint-not-small) {
	.banner {
		padding-bottom: 16%;
	}

	.overlayIcon {
		right: 1rem;
	}
}
